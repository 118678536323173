import application from './en/application';
import applicationMicroservice from './en/applicationMicroservice';
import filesystemMicroserivce from './en/filesystemMicroserivce';
import workerMicroservice from './en/workerMicroservice';
import apexHelmChartVersion from './en/apexHelmChartVersion';
import probeConfig from './en/probeConfig';

export default {
  ...application,
  ...applicationMicroservice,
  ...workerMicroservice,
  ...filesystemMicroserivce,
  ...apexHelmChartVersion,
  ...probeConfig,

  // Labels, Headers, etc
  accessManagement: 'Access Management',
  acceptingTrafficOnPort: 'Accepting traffic on port :port:',
  actions: 'Actions',
  active: 'Active',
  activity: 'Activity',
  added: 'Added',
  addAnotherRule: 'Add Rule',
  addApplication: 'Add Application',
  addApplicationPermission: 'Add Application Permission',
  addApplicationRole: 'Add Application Role',
  addApplicationGroup: 'Add Application Group',
  addEnvironmentVariable: 'Add Environment Variable',
  addEventNotification: 'Add Event Notification',
  addedExecutionSuccessfully: 'Added Execution Successfully',
  addExecution: 'Add Execution',
  addGroup: 'Add Group',
  addGroups: 'Add Groups',
  addGroupsToApplication: 'Add Groups to Application',
  addInstanceType: 'Add Instance Type',
  addLabel: 'Add Label',
  addMaintainers: 'Add Maintainers',
  addMaintainerToApplication: 'Add Maintainer to :applicationName: application',
  addMembers: 'Add Members',
  addMembersToGroup: 'Add Members to Group',
  addMembersToOrganization: 'Add Members to Organization',
  addMicroservice: 'Add Microservice',
  addOrganizations: 'Add Organizations',
  addOrganizationsToApplication: 'Add Organizations to Application',
  addPermission: 'Add Permission',
  addPermissions: 'Add Permissions',
  addPermissionsToRole: 'Add Permissions to Role',
  addRole: 'Add Role',
  addRoles: 'Add Roles',
  addSubnet: 'Add Subnet',
  addTag: 'Add Tag',
  addTrustedSource: 'Add Trusted Source',
  addUser: 'Add User',
  addUsers: 'Add Users',
  adminSecret: 'Admin Secret',
  age: 'Age',
  all: 'All',
  allActivity: 'All Activity',
  allGuides: 'All Guides',
  allResources: 'All Resources',
  amiType: 'AMI Type',
  application: 'Application',
  anUnexpectedErrorOccurred: 'An unexpected error occurred.',
  any: 'Any',
  apexHelmChartVersions: 'Apex Helm Chart Versions',
  apexInfrastructure: 'Apex Infrastructure',
  api: 'API',
  apiGroup: 'API Group',
  appHealthcheck: 'App Healthcheck',
  applicationImage: 'Application Image',
  applications: 'Applications',
  applicationsAndRoles: 'Applications and Roles',
  applicationRoles: 'Application Roles',
  approved: 'Approved',
  assignRoleToGroups: 'Assign Role to Groups',
  attach: 'Attach',
  available: 'Available',
  averageUtilization: 'Average Utilization',
  awsTags: 'AWS Tags',
  backgroundColor: 'Background Color',
  black: 'Black',
  bytes: 'Bytes',
  cancel: 'Cancel',
  canceled: 'Canceled',
  capacityType: 'Capacity Type',
  carNumber: 'Car Number',
  carNumberExplanation: 'Car numbers are between 1 and 6 characters. Letters and numbers are allowed in any order, spaces are allowed, but not at the beginning or end. Capital letters and lower case letters are not equal. Preceding 0s are signifcant. Examples: 7, 21, 09, 9, ABC, abc, 1ab, TRD 12',
  chartName: 'Chart Name',
  cli: 'CLI',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  close: 'Close',
  clusterRole: 'Cluster Role',
  complete: 'Complete',
  computeDetails: 'Compute Details',
  computeDetailsUpdateFailed: 'Failed to udpate compute details. Please check form errors.',
  computeDetailsUpdateSuccess: 'Compute details updated successfully, redeploying microservice.',
  configuration: 'Configuration',
  confirm: 'Confirm',
  confirmDeleteByName: 'Are you sure you want to delete :name:?',
  confirmDeleteApexHelmChartVersion: 'Are you sure you want to delete :chartName: version :version:?',
  confirmDeleteApplicationMicroservice: 'Are you sure you want to delete :microserviceName:?',
  confirmDeleteApplication: 'Are you sure you want to delete :applicationName:?',
  confirmDeleteApplicationPermission: ':permissionName: permission will be removed from all applications and roles.',
  confirmDeleteApplicationRole: ':roleName: role will be removed from all groups and users.',
  confirmDeleteDatabaseMicroservice: 'Are you sure you want to delete the database :name:?',
  confirmDeleteFilesystemMicroservice: 'Are you sure you want to delete :name:? This will cause a redeployment of all mounted microservices.',
  confirmDeleteGroup: 'Are you sure you want to delete the group :groupName:?',
  confirmDeletion: 'Confirm Deletion',
  confirmDeleteNodeGroup: 'Are you sure you want to delete the node group :name:?',
  confirmDeleteRedisMicroservice: 'Are you sure you want to delete the Redis microservice :name:?',
  confirmDeleteReplicationConfig: 'Are you sure you want to delete the ReplicationConfig?',
  confirmDeleteRoleConfig: 'Are you sure you want to delete the role config \':name:?\'. Rules will not be deleted',
  confirmDeleteTeam: 'Are you sure you want to delete the team :teamName:?',
  confirmDeleteUser: 'Are you sure you want to delete user :firstName: :lastName:?',
  confirmExecuteScheduledJobNow: 'Are you sure you want to execute :name: version :version: in :env:?',
  confirmImport: 'Confirm Import',
  confirmImportBody: 'Are you sure you want to import ":policyName:" with ARN :policyArn:?',
  confirmPromoteDeployment: 'Are you sure you want to promote :imageTag:?',
  confirmRemoveApplicationImage: 'Are you sure you want to remove the application image?',
  confirmRemoveGroup: 'Are you sure you want to remove :groupName:?',
  confirmRemoveGroupMember: 'Are you sure you want to remove :firstName: :lastName: from the group :groupName:?',
  confirmRemoveMaintainer: 'Are you sure you want to remove :firstName: :lastName: as a maintainer from the application :application:?',
  confirmRemoveName: 'Are you sure you want to remove :name:?',
  confirmRemoveOrgMember: 'Are you sure you want to remove :firstName: :lastName: from the :organizationName: organization?',
  confirmRemovePermission: 'Are you sure you want to remove permission :permissionName:?',
  confirmRemoveRoleFromGroup: 'Are you sure you want to remove :roleName: from :groupName:?',
  confirmS3BucketDeletion: 'All objects in this bucket will be deleted.',
  confirmStop: 'Confirm Stop',
  confirmStopReplication: 'Are you sure you want to stop Replication?',
  confirmUninstallMicroserviceEnv: 'Are you sure want to uninstall the :env: environment for :name:',
  confirmUpdatePassword: 'Are you sure you want to update the password?',
  copy: 'Copy',
  copiedSuccessfully: ':field: copied successfully',
  cores: 'Cores',
  cpu: 'CPU',
  create: 'Create',
  createApexHelmChartVersion: 'Create Apex Helm Chart Version',
  createAnotherGroup: 'Create another group',
  createdAt: 'Created At',
  createOrganization: 'Create Organization',
  created: 'Created',
  createdGroup: 'Created Group :groupName:',
  createGroup: 'Create Group',
  createIamPolicy: 'Create IAM Policy',
  createKubernetesRoleConfig: 'Create Kubernetes Role Config',
  createNewRoleRule: 'Create New Role Rule',
  createNodeGroup: 'Create Node Group',
  createReplicationConfig: 'Create Replication Config',
  createRole: 'Create Role',
  createTeam: 'Create Team',
  cron: 'Cron',
  dangerZone: 'Danger Zone',
  dark: 'Dark',
  database: 'Database',
  databaseConnectionDetails: 'Connection Details',
  databaseConnectionString: 'Database Connection String',
  databaseConnectionStringPlaceholder: 'postgres://user:password@host:port/database',
  databaseName: 'Database Name',
  day: 'Day',
  dayOfTheWeek: 'Day of the Week',
  dbStatusNotAvailable: 'Status unavailable at this time',
  delete: 'Delete',
  deleteApplication: 'Delete Application',
  deleteApplicationMicroserviceEnvironmentVariable: 'Delete Environment Variable',
  deleteS3MicroserviceEventNotification: 'Delete S3 Event Notification',
  deleteGroup: 'Delete Group',
  deleteApplicationMicroservice: 'Delete Microservice',
  deleteNodeGroup: 'Delete Node Group',
  deletePermission: 'Delete Permission',
  deleteReplicationConfig: 'Delete Replication Config',
  deleteRole: 'Delete Role',
  deleteRoleConfig: 'Delete Role Config',
  deleteSuccessful: 'Delete Successful',
  deleteTeam: 'Delete Team',
  deleteUser: 'Delete User',
  deploy: 'Deploy',
  deployedVersions: 'Deployed Versions',
  deployments: 'Deployments',
  deprecated: 'Deprecated',
  deprecationDate: 'Deprecation Date',
  description: 'Description',
  desired: 'Desired',
  desiredNodeCount: 'Desired Node Count',
  detail: 'Detail',
  details: 'Details',
  dev: 'Dev',
  disableNetworking: 'Disable Networking',
  dmsReplication: 'DMS Replication',
  domain: 'Domain',
  domainRequired: 'Domain is Required',
  edit: 'Edit',
  editApexHelmChartVersion: 'Edit Apex Helm Chart Version',
  editApplication: 'Edit Application',
  editApplicationDetails: 'Edit Application Details',
  editApplicationRole: 'Edit Application Role',
  editGroup: 'Edit Group',
  editIamPolicy: 'Edit IAM Policy',
  editNodeGroup: 'Edit Node Group',
  editPermission: 'Edit Permission',
  editRole: 'Edit Role',
  editRoleConfig: 'Edit Role Config',
  editTeam: 'Edit Team',
  editUser: 'Edit User',
  email: 'Email',
  enableServiceMesh: 'Enable Istio Service Mesh',
  endpoints: 'Endpoints',
  engine: 'Engine',
  engineVersion: 'Engine Version',
  environment: 'Environment',
  environmentVariables: 'Environment Variables',
  environmentVariablesSavedSuccessfully: 'Environment variables saved successfully',
  eolDate: 'EOL Date',
  ephemeralStorage: 'Ephemeral Storage',
  error: 'Error',
  errorFetchingStorageServices: 'We encountered an error fetching your storage services.',
  eventNotifications: 'Event Notifications',
  eventNotificationsSavedSuccessfully: 'Event notifications saved successfully',
  events: 'Events',
  executables: 'Executables',
  executeDate: 'Execute Date',
  executeJobName: 'Execute :jobName:',
  executeNow: 'Execute Now',
  failed: 'Failed',
  failureMessages: 'Failure Messages',
  filterPrefix: 'Filter Prefix',
  filterSuffix: 'Filter Suffix',
  firstName: 'First Name',
  forMoreInformation: 'For more information on how to use this field,',
  generallyAvailable: 'Generally Available',
  generalPurpose: 'General Purpose',
  globalSettings: 'Global Settings',
  groups: 'Groups',
  groupDetail: 'Group Detail',
  guidesAndTroubleshooting: 'Guides & Troubleshooting',
  hasura: 'Hasura',
  healthcheckPath: 'Healthcheck Path',
  healthcheckPathExplanation: 'This field sets the route for a healthcheck to your UI microservice. This is useful if you need a non-default healthcheck for frameworks like Laravel or server-side rendering tooling like Next.js. If you do not need custom functionality, you can leave blank and it will default to your application\'s root path.',
  healthcheckPathUpdatedSuccessfully: 'Healthcheck Path Updated Successfully',
  healthy: 'Healthy',
  here: 'here',
  hide: 'Hide',
  hourUtc: 'Hour (UTC)',
  httpPort: 'HTTP Port',
  httpPortExplanation: 'This value should match the EXPOSE statement in your microservice\'s Dockerfile',
  httpPortRequired: 'HTTP Port is required.',
  httpPortUpdatedSuccessfully: 'HTTP Port updated successfully',
  iamPolicies: 'IAM Policies',
  iamRole: 'IAM Role',
  icon: 'Icon',
  image: 'Image',
  imageRegistry: 'Image Registry',
  imageTag: 'Image Tag',
  impersonate: 'Impersonate',
  import: 'Import',
  importPolicy: 'Import Policy',
  info: 'Info',
  infrastructure: 'Infrastructure',
  initialDelaySeconds: 'Initial Delay Seconds',
  abbreviation: 'Abbreviation',
  initialsRequired: 'Abbreviation required',
  inProgress: 'In Progress',
  insertCodeHere: 'Insert your code here...',
  insights: 'Insights',
  instanceStatus: 'Database Status',
  instanceType: 'Instance Type',
  instanceTypeExplanation: 'Instance type allows you to customize the type of server your microservice will run on. For example, if your microservice is memory intensive, you could select "memory optimized". If left blank, it will be run on general purpose servers.',
  instanceTypes: 'Instance Types',
  job: 'Job',
  jobs: 'Jobs',
  jobEnqueuedSuccessfully: 'Job enqueued for execution successfully',
  json: 'JSON',
  jsonPolicy: 'JSON Policy',
  k8sLabels: 'Kubernetes Labels',
  k8sRoleConfigs: 'Kubernetes Role Configs',
  kubernetesRolesSavedSuccessfully: 'Kubernetes Roles updated successfully',
  key: 'Key',
  kubernetesRoles: 'Kubernetes Roles',
  labels: 'Labels',
  lambda: 'Lambda',
  lastName: 'Last Name',
  light: 'Light',
  limits: 'Limits',
  liveContainerDataError: 'Error fetching container live view data. Please try again later.',
  loading: 'Loading',
  loadMoreGroups: 'Load more groups',
  loadMorePermissions: 'Load more permissions',
  loadMoreUsers: 'Load more users',
  logo: 'Logo',
  logoImageRequired: 'Logo Image is Required',
  logs: 'Logs',
  logsFromPreviousContainer: 'Logs from Previous Container',
  logsFromStdOutAndStdErr: 'This will show only logs sent to your container\'s stdout and stderr streams.',
  maintainers: 'Maintainers',
  masterPassword: 'Master Password',
  masterUsername: 'Master Username',
  maximumNodeCount: 'Maximum Node Count',
  member: 'Member',
  members: 'Members',
  memory: 'Memory',
  metrics: 'Metrics',
  microservice: 'Microservice',
  microservices: 'Microservices',
  microserviceType: 'Microservice Type',
  minimumNodeCount: 'Minimum Node Count',
  minute: 'Minute',
  month: 'Month',
  mountPath: 'Mount Path',
  name: 'Name',
  network: 'Network',
  networking: 'Networking',
  networkConfiguration: 'Network Configuration',
  networkPort: 'Network Port',
  noDbInstancesExistYet: 'We are currently spinning up your database. Please check back in a few minutes.',
  nodeGroups: 'Node Groups',
  noDeploymentsFound: 'No Deployments Found',
  noHpasFound: 'No Hpas Found',
  noLongerSupported: 'No Longer Supported',
  none: 'None',
  noPodSelected: 'No container is selected. To view logs, please select a container from the above dropdown.',
  noPodsFound: 'We did not find any active containers for this microservice. If you believe this an error please reach out to an admin for support.',
  noRedisInstancesExistYet: 'We are currently spinning up your redis. Please check back in a few minutes.',
  noRolesHaveBeenDefined: 'No roles have been defined',
  noRunningContainersFound: 'We could not find any containers running for this microservice.',
  noScheduledJobs: 'You have not created any scheduled jobs yet.',
  noStorageServices: 'You have not created any storage services yet.',
  ['n/a']: 'N/A',
  notAcceptingTraffic: 'Not accepting traffic',
  notAvailableYet: 'not available yet',
  notDeployed: 'Not deployed',
  notImplemented: 'Not implemented',
  notSet: 'Not Set',
  oauthCredentialsUpdated: 'Oauth Credentials Updated',
  objectStore: 'Object Store',
  isOfficialApp: 'Is Official App',
  okay: 'Okay',
  open: 'Open',
  openApp: 'Open app',
  onDemandInstance: 'ON_DEMAND',
  organization: 'Organization',
  organizations: 'Organizations',
  orgDoesNotHaveAppRoles: 'Your organization does not have any roles for this application yet.',
  overview: 'Overview',
  pageNotFound: 'Page Not Found',
  password: 'Password',
  passwordChangeQueuedSuccessfully: 'Password change queued successfully.',
  permissions: 'Permissions',
  pipeline: 'Pipeline',
  pipelines: 'Pipelines',
  policyJson: 'Policy Json',
  policyName: 'Policy Name',
  port: 'Port',
  previousContainer: 'Previous Container',
  previousContainerLogExplanation: 'See the logs from previous containers that have crashed for the selected pod.',
  primaryEndpoint: 'Primary Endpoint',
  primaryPort: 'Primary Port',
  prod: 'Prod',
  prodDeployedVersion: 'Prod Deployed Version',
  promote: 'Promote',
  promotionStartedSuccessfully: 'Promotion started successfully',
  queued: 'Queued',
  queuedForRemoval: ':name: has been queued for removal',
  queuedForUnistall: 'Queued for uninstall',
  rdsEndpoint: 'RDS Endpoint',
  reachOutToSupport: 'If you believe this is an error, please reach out for support.',
  readerEndpoint: 'Reader Endpoint',
  readerPort: 'Reader Port',
  readMoreAboutKubernetesTaints: 'Read more about each of these options',
  readTheDocs: 'checkout our documentation',
  ready: 'Ready',
  recentActivity: 'Recent Activity',
  redis: 'Redis',
  redisNotProvisionedForEnvironment: 'A redis instance has not been provisioned for this environment yet',
  refresh: 'Refresh',
  regenerateClientCredentials: 'Regenerate Client Credentials',
  relationalDatabase: 'Relational Database',
  remove: 'Remove',
  removeGroup: 'Remove Group',
  removeGroupMember: 'Remove Group Member',
  removeLogo: 'Remove Logo',
  removeMaintainer: 'Remove Maintainer',
  removeOrganization: 'Remove Organization',
  removeOrganizationMember: 'Remove Organization Member',
  removePermission: 'Remove Permission',
  removePhoto: 'Remove Photo',
  removeUser: 'Remove User',
  replicas: 'Replicas',
  replicaStatusUnavailble: 'Replica Status Unavailble at this time',
  replication: 'Replication',
  replicationConfigDoesNotExistYet: 'ReplicationConfig does not exist yet',
  replicationConfigJson: 'Replication Config JSON',
  replicationCreateTime: 'Replication Create Time',
  replicationDataIsNotAvailable: 'Replication Data is not available',
  replicationQueuedForCreation: 'ReplicationConfig queued for creation',
  replicationQueuedForDeletion: 'ReplicationConfig queued for deletion',
  replicationQueuedForStart: 'Replication queued for start',
  replicationQueuedForStop: 'Replication queued for stop',
  replicationType: 'Replication Type',
  replicationUpdateTime: 'Replication Update Time',
  requested: 'Requested',
  resource: 'Resource',
  resources: 'Resources',
  restarts: 'Restarts',
  resultsPerPage: 'Results Per Page',
  retry: 'Retry',
  role: 'Role',
  roles: 'Roles',
  roleRule: 'Role :index:',
  roleType: 'Role Type',
  route: 'Route',
  rules: 'Rules',
  rulesWillNotBeDeleted: 'Rules will not be deleted.',
  runtimeLogs: 'Runtime Logs',
  s3: 'S3',
  save: 'Save',
  savedSuccessfully: 'Saved Successfully',
  saving: 'Saving',
  scalability: 'Scalability',
  scalesAt: 'Scales At',
  scheduledJob: 'Scheduled Job',
  scheduledJobs: 'Scheduled Jobs',
  scheduledExecutions: 'Scheduled Executions',
  searchApiGroupResources: 'Search API Group Resources',
  searchApplicationRoles: 'Search :appName: roles',
  searchBy: 'Search By',
  searchGroups: 'Search groups',
  searchPermissions: 'Search permissions',
  searchPolicyNameOrArn: 'Search Policy Name or ARN',
  searchRoleConfigs: 'Search Role Configs',
  searchTeams: 'Search teams',
  searchUsers: 'Search users',
  selectApiGroup: 'Select API Group',
  selectAPod: 'Select a Pod',
  selectEngine: 'Select Engine',
  selectEngineVersion: 'Select Engine Version',
  selectMicroserviceType: 'Select Microservice Type',
  selectMicroservice: 'Select Microservice',
  selectResource: 'Select Resource',
  selectRoleType: 'Select Role Type',
  selectType: 'Select Type',
  selectVersion: 'Select Version',
  series: 'Series',
  settings: 'Settings',
  show: 'Show',
  size: 'Size',
  spotInstance: 'SPOT',
  stage: 'Stage',
  stages: 'Stages',
  staging: 'Staging',
  startReplicationConfig: 'Start Replication Config',
  startReplicationType: 'Start Replication Type',
  status: 'Status',
  stopReplicationConfig: 'Stop Replication Config',
  storage: 'Storage',
  storageSize: 'Storage Size',
  submit: 'Submit',
  subnets: 'Subnets',
  success: 'Success',
  tags: 'Tags',
  tagsAndLabels: 'AWS Tags and Kubernetes Labels',
  taint: 'Taint',
  targetEnvironment: 'Target Environment',
  team: 'Team',
  teams: 'Teams',
  terminating: 'Terminating',
  textColor: 'Text Color',
  thisOperationCannotBeUndone: 'This operation cannot be undone!',
  tilePreview: 'Tile Preview',
  timeoutSeconds: 'Timeout Seconds',
  timestamp: 'Timestamp',
  toggleServiceMesh: 'Toggle Istio Service Mesh',
  toggleServiceMeshError: 'Error Toggling the Service Mesh. Please check logs.',
  toggleServiceMeshSuccessful: 'Service mesh toggled successfully. Please watch for all compute-bound services to be deployed.',
  trustedIps: 'Trusted Ips',
  trustedSource: 'Trusted Source',
  trustedSources: 'Trusted Sources',
  type: 'Type',
  unableToCalculate: 'Unable to Calulate',
  unableToGetResourcesForGivenApiGroupAtThisTime: 'Unable to get Resources for the given API Group at this time.',
  unapproved: 'Unapproved',
  unavailable: 'Unavailable',
  unhealthy: 'Unhealthy',
  uninstall: 'Uninstall',
  unmount: 'Unmount',
  update: 'Update',
  updated: 'Updated',
  updatedAt: 'Updated At',
  updatedCredentialsMessage: 'Oauth credentials successfully updated. These credentials WILL NOT be shown again.',
  updatePassword: 'Update Password',
  updateRedisPassword: 'Upade Redis Password',
  updatedSuccessfully: 'Updated Successfully',
  upload: 'Upload',
  uploadLogo: 'Upload Logo',
  uploadPhoto: 'Upload Photo',
  url: 'URL',
  userAccess: 'User Access',
  username: 'Username',
  users: 'Users',
  userDetail: 'User Detail',
  userManagement: 'User Management',
  validDomainMessage: 'Must be a valid Domain',
  value: 'Value',
  verbs: 'Verbs',
  version: 'Version',
  versionTagUpdatesSuccessfuly: ':env: has been updated to :imageTag:',
  view: 'View',
  white: 'White',
  worker: 'Worker',
  youAreNotAuthorizedToPerformAction: 'You are not authorized to perform this action',
  youAreNotAuthorizedToViewThisPage: 'You are not authorized to view this page',

  // Validation messages
  apiGroupRequired: 'API Group is required.',
  apiRouteRuleExplanation: 'Allowed characters are letters, numbers, forward slashes, and hyphens. Must begin with a letter and end with a letter or number. Examples "api", "api/v1"',
  backgroundColorRequired: 'Background Color is required',
  carNumber6CharactersOrLess: 'Car Number must be between 1 and 6 characters. Alpha characters are allowed. No leading or trailing spaces.',
  carNumberRequired: 'Car Number is Required',
  databaseEngineRequired: 'Database Engine is required',
  databaseEngineVersionRequired: 'Database Engine Version is required',
  databaseNameRequired: 'Database Name is required',
  emailRequired: 'Email is required',
  environmentRequired: 'Environment is required and must be one of \'dev\', \'staging\', or \'prod\'',
  environmentVariableEncryptedExplanation: 'Values are encrypted when saved',
  environmentVariableNameFormat: 'Only upper-case letters, digits, and "_" are allowed',
  environmentVariableNameUnique: 'Environment variable names must be unique per environment',
  eventNotificationNameFormat: 'Event notification name can contain up to 255 characters',
  eventNotificationNameUnique: 'Event notification names must be unique per environment',
  executionDateMustBeFuture: 'An execution date cannot be set in the past',
  firstNameRequired: 'First Name is required',
  hasuraAdminSecretExplanation: 'The secret key required to access the Hasura instance',
  hasuraAdminSecretRequired: 'Admin secret is required',
  hasuraDatabaseConnectionStringExplanation: 'Used to connect Hasura to a Postgres instance. Hasura will automatically add it to the list of connected data sources.',
  hasuraDatabaseConnectionStringRequired: 'Database connection string is required',
  iconRequired: 'Icon is required',
  invalidFormat: 'Invalid Format',
  lastNameRequired: 'Last Name is required',
  maxNodeCountMustBeLargerThanMinNodeCount: 'Maximum node count must be larger than minimum node count',
  nameRequired: 'Name is required',
  noOrganizationApplicationsHeader: 'Your organization does not have access to any applications yet.',
  noOrganizationApplications: 'If you do not see any applications, your organization has not been granted access to any applications yet. Please contact an Admin for assistance.',
  noWhitespace: 'Spaces not allowed',
  isOfficialAppRequired: 'Is official app is required',
  permissionNameSnakeCase: 'Permission names must only contain lower case letters and underscores',
  routeRequired: 'Route is required',
  seriesIdRequired: 'Series ID is required',
  textColorRequired: 'Text Color is required',
  typeRequired: 'Type is required.',
  uniqueEnvironmentNameCombo: 'Environment and name combination must be unique',
  validTrustedIpsMsg: 'Trusted Ips must be a valid IpV4/IpV6 CIDR block or address',
  valueRequired: 'Value is required',

  // Error fetching data
  errorLoadingOrganizationApplications: 'We encountered a problem while loading your organization\'s applications. Please contact an admin for assistance.',
  organizationIdNotValid: 'The organization ID we found for your organization does not seem to be valid. Please contact an admin.',
  organizationIdRouteError: 'The Organization ID you are looking for was not found, or is not valid.',

  // Resources, Guides, and Troubleshooting Topics
  documentation: 'Documentation',
  apiDocumentation: 'API Documentation',
  styleGuide: 'Style Guide',
  howToDeployANewMicroservice: 'How to deploy a new microservice',
  howToBuildADockerImage: 'How to build a Docker image',
  howToUseTheSdk: 'How to use the SDK',
  howToDevelopLocallyWithTheMockPlatformServer: 'How to develop locally with the mock platform server',

  // Popovers, etc
  containerLiveViewExplanation: 'This shows live data about the containers running for this particular microservice. Data is updated every second.',
  cpuExplanation: 'CPU is measured in units of millicores. This means that the number you put here will be in increments of 0.001 cores. 250 means 1/4 of a core, and is the default value.',
  ephemeralStorageExplanation: 'Ephemeral Storage follows the lifecycle of your container. If your container gets rescheduled to a new node or restarted you will lose the files you place in ephemeral storage. If you need persistent storage, please use the Object Storage or File System services.',
  memoryExplanation: 'Memory defines the amount of RAM your container is allowed to use. Numbers are only taken in in MB (megabytes).',
  networkPortExplanation: 'The network port is the port exposed in your container and to the Apex Network. Example: Redis typically listens on port 6379',
  organizationApplicationAndRoles: 'This section allows you to attach applications and roles to the group to grant permissions for those applications.',
  organizationUserAccess: 'This section allows you to attach teams and users to this group to grant specific permissions to the applications chosen above.',

  // k8s Taint Effects
  noSchedule: 'NoSchedule',
  noExecute: 'NoExecute',
  preferNoSchedule: 'PreferNoSchedule',
  selectTaintEffect: 'Select Taint Effect...',
  taintExplanation: 'Taints control how pods can or cannot be scheduled onto your nodes in a node group. Leave this blank to allow any workload to be scheduled onto your node group',
};
