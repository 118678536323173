import React, { useState, useRef } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Badge, Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateTime } from 'helpers/datetime';
import { isValidSemVer } from 'helpers/semver';
import { useRetryApplicationMicroserviceDeploymentMutation } from 'api/applicationMicroserviceSlice';
import ConfirmPromotionModal from './ConfirmPromotionModal';
import IDeployment from 'types/IDeployment';
import { SortDirection } from 'types/ListSearchTypes';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const DeploymentTable: React.FC<{
  deployments: IDeployment[];
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  sortDirection: SortDirection;
  isDbMicroservice: boolean;
  canPromote: boolean;
}> = (props) => {
  const { deployments, sortBy, setSortBy, sortDirection, isDbMicroservice, canPromote } = props;
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [retryDeployment, { isLoading }] = useRetryApplicationMicroserviceDeploymentMutation();
  const [showConfirmPromotionModal, setShowConfirmPromotionModal] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState<{
    deploymentId: string | null;
    imageTag: string | null;
  }>({ deploymentId: null, imageTag: null });

  const columns: InteractiveTableColumn<IDeployment>[] = [
    {
      title: translate('created'),
      dataIndex: 'created_at',
      sortDirection: sortBy === 'created_at' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('created_at'),
        className: 'clickable',
      }),
      render: (createdAt: string) => formatDateTime(createdAt),
    },
    {
      title: translate('updated'),
      dataIndex: 'updated_at',
      sortDirection: sortBy === 'updated' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('updated'),
        className: 'clickable',
      }),
      render: (updatedAt: string) => formatDateTime(updatedAt),
    },
    isDbMicroservice
      ? {
        title: translate('engineVersion'),
        dataIndex: 'engine_version',
        sortDirection: sortBy === 'engine_version' && sortDirection,
        onHeader: () => ({
          onClick: () => setSortBy('engine_version'),
          className: 'clickable',
        }),
      } : {
        title: translate('imageTag'),
        dataIndex: 'image_tag',
        sortDirection: sortBy === 'image_tag' && sortDirection,
        onHeader: () => ({
          onClick: () => setSortBy('image_tag'),
          className: 'clickable',
        }),
      },
    {
      title: translate('targetEnvironment'),
      dataIndex: 'target_environment',
      sortDirection: sortBy === 'target_environment' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('target_environment'),
        className: 'clickable',
      }),
    },
    {
      title: translate('apexVersion'),
      dataIndex: 'helm_chart_version',
      sortDirection: sortBy === 'helm_chart_version' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('helm_chart_version'),
        className: 'clickable',
      }),
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      sortDirection: sortBy === 'status' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('status'),
        className: 'status',
      }),
      render: (_: string, { status, exception_message: exceptionMessage }: IDeployment) => (
        <>
          <span className="me-2">{status}</span>
          {
            status === 'FAILED'
            && (
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    {exceptionMessage}
                  </Tooltip>
                )}
              >
                <Badge pill bg="secondary">
                  <FontAwesomeIcon icon="info" />
                </Badge>
              </OverlayTrigger>
            )
          }
        </>
      ),
    },
    {
      title: translate('type'),
      dataIndex: 'uninstall',
      sortDirection: sortBy === 'uninstall' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('uninstall'),
        className: 'clickable',
      }),
      render: (uninstall: boolean | null) => {
        if (uninstall) {
          return (
            <div className="text-danger">
              <span>{translate('uninstall')}</span>
              {/** @ts-expect-error fa can't find icon in types */}
              <FontAwesomeIcon icon="excavator" className='ms-2' />
            </div>
          );
        }

        return (
          <div className="text-primary">
            <span>{translate('deploy')}</span>
            {/** @ts-expect-error fa can't find icon in types */}
            <FontAwesomeIcon icon="cloud-arrow-up" className='ms-2' />
          </div>
        );
      },
    },
  ];

  if (canPromote) {
    columns.push(
      {
        title: 'Actions',
        render: (_: string, deployment: IDeployment) => {
          const buttons = [];

          const eligibleForPromotion = deployment.status === 'COMPLETE'
            && (['dev', 'staging'].includes(deployment.target_environment))
            && (isValidSemVer(deployment.image_tag) || ['database', 's3', 'redis', 'filesystem'].includes(deployment.microservice_type));

          if (eligibleForPromotion) {
            buttons.push(
              <Button
                size="sm"
                className="me-2"
                onClick={() => {
                  setPromotionDetails({
                    deploymentId: deployment.id,
                    imageTag: deployment.image_tag || deployment.engine_version,
                  });
                  setShowConfirmPromotionModal(true);
                  // trigger overlay close
                  if (wrapperRef.current !== null) wrapperRef.current.click();
                }}
              >
                <FontAwesomeIcon icon="rocket-launch" />
                {translate('promote')}
              </Button>
            );
          }

          if (!['CREATED', 'IN_PROGRESS'].includes(deployment.status)) {
            buttons.push(
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  retryDeployment({ deploymentId: deployment.id });
                  // trigger overlay close
                  if (wrapperRef.current !== null) wrapperRef.current.click();
                }}
                disabled={isLoading || deployment.status === 'IN_PROGRESS'}

              >
                <FontAwesomeIcon icon={"arrows-rotate" as IconProp} fixedWidth />
                {translate('retry')}
              </Button>
            );
          }

          if (buttons.length === 0) return null;

          return (
            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose
              overlay={(
                <Popover>
                  <Popover.Body>
                    {buttons}
                  </Popover.Body>
                </Popover>
              )}
            >
              <Button
                variant="link"
                style={{ color: 'white' }}
              >
                <FontAwesomeIcon icon="ellipsis-v" />
              </Button>
            </OverlayTrigger>
          );
        },
      }
    );
  }
  return (
    <div ref={wrapperRef}>
      <InteractiveTable
        dataId="id"
        data={deployments}
        columns={columns}
      />
      <ConfirmPromotionModal
        show={showConfirmPromotionModal}
        handleClose={() => setShowConfirmPromotionModal(false)}
        deploymentId={promotionDetails.deploymentId}
        imageTag={promotionDetails.imageTag}
      />
    </div>
  );
};

export default DeploymentTable;
