import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { translate } from "@apex/react-toolkit/lib";
import ConfirmationModal from "common/ConfirmationModal/ConfirmationModal";
import { useDeleteRedisMicroserviceMutation } from "api/redisMicroserviceSlice";
import { IApplicationMicroserviceRedis } from "types/application/microservice/IRedisMicroservice";

const DangerZone: React.FC<{
  microservice: IApplicationMicroserviceRedis;
}> = ({ microservice }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteRedisMicroservice, { isLoading }] =
    useDeleteRedisMicroserviceMutation();

  return (
    <>
      <ConfirmationModal
        show={!!showDeleteModal}
        title={translate("confirmDeletion")}
        confirmationText={translate("confirmDeleteRedisMicroservice", {
          name: microservice.name,
        })}
        disabled={isLoading}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={async () =>
          await deleteRedisMicroservice(microservice.microserviceable)
        }
        successMessage={translate("queuedForRemoval", {
          name: microservice.name,
        })}
      />
      <Card bg="dark">
        <Card.Body>
          <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
            {translate("deleteApplicationMicroservice")}
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default DangerZone;
