import React from 'react';
import { Accordion, Card, Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceHealthCheckStatus from 'common/applicationMicroservice/ApplicationMicroserviceHealthCheckStatus';
import useHealthcheck from 'hooks/useHealthcheck';
import styles from './AuthDetail.module.css';
import ContainerLiveView from 'common/computeBoundMicroservices/ContainerLiveView';
import { IApplicationMicroserviceAuth } from 'types/application/microservice/IAuthMicroservice';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import ApexHelmChartVersionExplainTooltip from 'common/applicationMicroservice/ApexHelmChartVersionExplainTooltip';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { useParams } from 'react-router-dom';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import MicroserviceChartVersionSelectorCard from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';

const AuthDetail: React.FC<{ microservice: IApplicationMicroserviceAuth }> = ({ microservice }) => {
  const isHealthy = useHealthcheck(microservice.id, 'prod');
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
                {(isHealthy !== null) && (
                  <span className={`ms-3 align-middle ${styles.healthCheckStatusContainer}`}>
                    <ApplicationMicroserviceHealthCheckStatus isHealthy={!!isHealthy} />
                  </span>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('route')}:</strong> <span>/auth</span>
            </Col>
          </Row>
          <Row>
            <ContainerLiveView microservice={microservice} />
          </Row>
        </Col>
      </Row >
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <Accordion>
              <Card bg="dark" className="mt-3">
                <Card.Header>
                  <Row>
                    <Col className="text-start">
                      {translate('apexUiVersions')}  <ApexHelmChartVersionExplainTooltip />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <span className="text-muted">
                            {translate('dev')} {microservice.dev_chart_version.version}
                          </span>
                        </Col>
                        <Col>
                          <span className="text-muted">
                            {translate('staging')} {microservice.staging_chart_version.version}
                          </span>
                        </Col>
                        <Col>
                          <span className="text-muted">
                            {translate('prod')} {microservice.prod_chart_version.version}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="text-end">
                      <AccordionToggleLink
                        eventKey="apex-versions"
                        inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                        activeText={translate('close')}
                      />
                    </Col>
                  </Row>
                </Card.Header>
              </Card>
              <Accordion.Collapse eventKey="apex-versions">
                <MicroserviceChartVersionSelectorCard
                  microservice={microservice}
                  isDeveloperAuthorized={isDeveloperAuthorized}
                />
              </Accordion.Collapse>
            </Accordion>
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} canPromote={isDeveloperAuthorized} />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container >
  );
};

export default AuthDetail;
