export default {
  healthProbeConfigUpdateSuccess: 'Health Probe Config updated successfully, redeploying microservice.',
  HealthProbeConfiguration: 'Health Probe Configuration',
  liveness: 'Liveness',
  livenessProbeExplanation: 'Liveness probes determine when to restart a container. A container will automatically be restarted if the probe continuously fails.',
  readiness: 'Readiness',
  readinessProbeExplanation: 'Readiness probes determine when a container is ready to start accepting traffic. If a readiness probe fails, traffic will not be allowed into the container.',
  startup: 'Startup',
  startupProbeExplanation: 'A startup probe verifies whether the application within a container is started. Liveness and Readiness probes are disabled until the startup probe succeeds.',
}
