import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Accordion, Badge, Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { UiMicroservice } from 'types/application/microservice/IUiMicroservice';
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import HttpPortForm from 'common/applicationMicroservice/HttpPortForm';
import DangerZone from './DangerZone';
import HealthcheckPathForm from 'App/AppRoutes/Applications/Application/Microservices/Detail/UiDetail/ConfigurationTab/HealthcheckPathForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';
import ComputeDetailsForm from 'common/computeBoundMicroservices/ComputeDetailsForm';
import MicroserviceChartVersionSelectorCard from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard';
import ApexHelmChartVersionExplainTooltip from 'common/applicationMicroservice/ApexHelmChartVersionExplainTooltip';
import IamPolicyableAdder from 'common/infrastructure/iamPolicies/IamPolicyableAdder';
import { useGetPolicyablePoliciesQuery } from 'api/iamPolicySlice';
import HttpHealthProbeConfiguration from 'common/probeConfigs/HttpHealthProbeConfiguration';

const ConfigurationTab: React.FC<{
  microservice: UiMicroservice;
  isDeveloperAuthorized: boolean;
  isSuperUserAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized, isSuperUserAuthorized }) => {
  const { data: currentPolicies } = useGetPolicyablePoliciesQuery({
    policyableId: microservice.application_microserviceable_id,
    policyableType: microservice.application_microserviceable_type,
  });

  return (
    <Accordion alwaysOpen>
      {
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('computeDetails')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="computeDetails"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="computeDetails">
              <ComputeDetailsForm computeDetails={microservice.microserviceable.compute_details} applicationMicroserviceId={microservice.id} isDeveloperAuthorized={isDeveloperAuthorized} />
            </Accordion.Collapse>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('HealthProbeConfiguration')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="probeConfigs"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="probeConfigs">
              <HttpHealthProbeConfiguration microservice={microservice.microserviceable} />
            </Accordion.Collapse>
          </>
        )
      }
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('apexUiVersions')}  <ApexHelmChartVersionExplainTooltip />
            </Col>
            <Col>
              <Row>
                <Col>
                  <span className="text-muted">
                    {translate('dev')} {microservice.dev_chart_version.version}
                  </span>
                </Col>
                <Col>
                  <span className="text-muted">
                    {translate('staging')} {microservice.staging_chart_version.version}
                  </span>
                </Col>
                <Col>
                  <span className="text-muted">
                    {translate('prod')} {microservice.prod_chart_version.version}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="apex-versions"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText={translate('close')}
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="apex-versions">
        <MicroserviceChartVersionSelectorCard
          microservice={microservice}
          isDeveloperAuthorized={isDeveloperAuthorized}
        />
      </Accordion.Collapse>
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('environmentVariables')}
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.environment_variables_count} {translate('environmentVariables')}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="environmentVariables"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="environmentVariables">
        <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} />
      </Accordion.Collapse>

      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('httpPort')}
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.microserviceable.http_port}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="httpPort"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="httpPort">
        <HttpPortForm
          microserviceId={microservice.id}
          httpPort={microservice.microserviceable.http_port}
          isDeveloperAuthorized={isDeveloperAuthorized}
        />
      </Accordion.Collapse>

      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('healthcheckPath')}
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="right"
                rootClose
                overlay={(
                  <Popover>
                    <Popover.Body>
                      {translate('healthcheckPathExplanation')}
                    </Popover.Body>
                  </Popover>
                )}
              >
                <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                  <FontAwesomeIcon icon="info" />
                </Badge>
              </OverlayTrigger>
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.microserviceable.healthcheck_path || '/'}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="healthcheck"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="healthcheck">
        <Card bg="dark">
          <Card.Body>
            <HealthcheckPathForm
              // @ts-expect-error stupid
              applicationId={microservice.application.id}
              microserviceableId={microservice.microserviceable.id}
              healthcheckPath={microservice.microserviceable.healthcheck_path?.replace(/^\//, '')}
            />
          </Card.Body>
        </Card>
      </Accordion.Collapse>
      {
        isSuperUserAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('iamPolicies')}
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {currentPolicies?.length} {translate('iamPolicies')}
                    </span>
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="iam-policy-adder"
                      inactiveText={isSuperUserAuthorized ? translate('edit') : translate('view')}
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="iam-policy-adder">
              <IamPolicyableAdder
                policyableType={microservice.application_microserviceable_type}
                policyableId={microservice.application_microserviceable_id}
              />
            </Accordion.Collapse>
          </>
        )
      }

      { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }

      { // Danger zone
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start text-danger">
                    {translate('dangerZone')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="dangerZone"
                      inactiveText="Open"
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="dangerZone">
              <DangerZone microservice={microservice} />
            </Accordion.Collapse>
          </>
        )
      }
    </Accordion>
  );
};

export default ConfigurationTab;
