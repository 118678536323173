import { apiSlice } from 'api/apiSlice';
import IProbeConfig from 'types/application/microservice/IProbeConfig';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    editProbeConfig: builder.mutation<IProbeConfig, IProbeConfig>({
      query: data => ({ url: `probe-configs/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useEditProbeConfigMutation,
} = extendedApiSlice;
