import { Card } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ProbeConfigForm, { InitialValues } from '../ProbeConfigForm';
import { ProbeableMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { useEditProbeConfigMutation } from 'api/probeConfigSlice';
import useToast from 'hooks/useToast';
import InfoPopover from 'common/InfoPopover';
import IProbeConfig from 'types/application/microservice/IProbeConfig';

const HttpHealthProbeConfiguration: React.FC<{ microservice: ProbeableMicroservice }> = ({ microservice }) => {
  const toast = useToast();
  const [editProbeConfig] = useEditProbeConfigMutation();

  const handleSubmit = async (values: InitialValues, probeConfig: IProbeConfig) => {
    const result = await editProbeConfig(
      {
        ...probeConfig,
        ...values,
      }
    );

    if ('error' in result) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: result.error.message,
        autohide: false,
      });
    } else if ('data' in result) {
      toast({
        bg: 'success',
        title: translate('success'),
        message: translate('healthProbeConfigUpdateSuccess'),
        autohide: true,
      });
    }
  };

  const routePrefix = microservice.route || '';

  return (
    <Card bg="dark">
      <Card.Body>
        <h4>
          <span>
            {translate('startup')}
          </span>
          <span style={{ fontSize: '1.25rem' }}>
            <InfoPopover
              infoText={translate('startupProbeExplanation')}
              trigger={['hover', 'focus']}
              placement="right"
            />
          </span>
        </h4>
        <ProbeConfigForm
          routePrefix={routePrefix}
          initialValues={microservice.startup_probe_config}
          disabled={false}
          onSubmit={(values: InitialValues) => handleSubmit(values, microservice.startup_probe_config)}
        />
        <hr />
        <h4>
          <span>
            {translate('readiness')}
          </span>
          <span style={{ fontSize: '1.25rem' }}>
            <InfoPopover
              infoText={translate('readinessProbeExplanation')}
              trigger={['hover', 'focus']}
              placement="right"
            />
          </span>
        </h4>
        <ProbeConfigForm
          routePrefix={routePrefix}
          initialValues={microservice.readiness_probe_config}
          disabled={false}
          onSubmit={(values: InitialValues) => handleSubmit(values, microservice.readiness_probe_config)}
        />
        <hr />
        <h4>
          <span>
            {translate('liveness')}
          </span>
          <span style={{ fontSize: '1.25rem' }}>
            <InfoPopover
              infoText={translate('livenessProbeExplanation')}
              trigger={['hover', 'focus']}
              placement="right"
            />
          </span>
        </h4>
        <ProbeConfigForm
          routePrefix={routePrefix}
          initialValues={microservice.liveness_probe_config}
          disabled={false}
          onSubmit={(values: InitialValues) => handleSubmit(values, microservice.liveness_probe_config)}
        />
      </Card.Body>
    </Card>
  );
};

export default HttpHealthProbeConfiguration;
