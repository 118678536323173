import React from 'react';
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import HttpPortForm from 'common/applicationMicroservice/HttpPortForm';
import AccordionToggleLink from 'common/AccordionToggleLink';
import DangerZone from './DangerZone';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';
import { IApplicationMicroserviceApi } from 'types/application/microservice/IApiMicroservice';
import ComputeDetailsForm from 'common/computeBoundMicroservices/ComputeDetailsForm';
import MicroserviceChartVersionSelectorCard from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard';
import ApexHelmChartVersionExplainTooltip from 'common/applicationMicroservice/ApexHelmChartVersionExplainTooltip';
import IamPolicyableAdder from 'common/infrastructure/iamPolicies/IamPolicyableAdder';
import { useGetPolicyablePoliciesQuery } from 'api/iamPolicySlice';
import HttpHealthProbeConfiguration from 'common/probeConfigs/HttpHealthProbeConfiguration';

const Configuration: React.FC<{
  isDeveloperAuthorized: boolean
  isSuperUserAuthorized: boolean
  microservice: IApplicationMicroserviceApi
}> = ({ microservice, isDeveloperAuthorized, isSuperUserAuthorized }) => {
  const { data: currentPolicies } = useGetPolicyablePoliciesQuery({
    policyableId: microservice.application_microserviceable_id,
    policyableType: microservice.application_microserviceable_type,
  });

  return (
    <Accordion alwaysOpen>
      {
        isDeveloperAuthorized && (
          <>
            <Card bg="dark">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('computeDetails')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="computeDetails"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="computeDetails">
              <ComputeDetailsForm
                computeDetails={microservice.microserviceable.compute_details}
                applicationMicroserviceId={microservice.id}
                isDeveloperAuthorized={isDeveloperAuthorized}
              />
            </Accordion.Collapse>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('HealthProbeConfiguration')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="probeConfigs"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="probeConfigs">
              <HttpHealthProbeConfiguration microservice={microservice.microserviceable} />
            </Accordion.Collapse>
          </>
        )
      }
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('apexApiVersions')} <ApexHelmChartVersionExplainTooltip />
            </Col>
            <Col>
              <Row>
                <Col>
                  <span className="text-muted">
                    {translate('dev')} {microservice.dev_chart_version.version}
                  </span>
                </Col>
                <Col>
                  <span className="text-muted">
                    {translate('staging')} {microservice.staging_chart_version.version}
                  </span>
                </Col>
                <Col>
                  <span className="text-muted">
                    {translate('prod')} {microservice.prod_chart_version.version}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="apex-versions"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText={translate('close')}
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="apex-versions">
        <MicroserviceChartVersionSelectorCard
          microservice={microservice}
          isDeveloperAuthorized={isDeveloperAuthorized}
        />
      </Accordion.Collapse>
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('environmentVariables')}
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.environment_variables_count} {translate('environmentVariables')}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="0"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText={translate('close')}
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="0">
        <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} />
      </Accordion.Collapse>
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('httpPort')}
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.microserviceable.http_port}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="1"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText={translate('close')}
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="1">
        <HttpPortForm
          microserviceId={microservice.id}
          httpPort={microservice.microserviceable.http_port}
          isDeveloperAuthorized={isDeveloperAuthorized}
        />
      </Accordion.Collapse>
      {
        isSuperUserAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('iamPolicies')}
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {currentPolicies?.length} {translate('iamPolicies')}
                    </span>
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="iam-policy-adder"
                      inactiveText={isSuperUserAuthorized ? translate('edit') : translate('view')}
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="iam-policy-adder">
              <IamPolicyableAdder
                policyableType={microservice.application_microserviceable_type}
                policyableId={microservice.application_microserviceable_id}
              />
            </Accordion.Collapse>
          </>
        )
      }
      { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }

      { // Danger zone
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start text-danger">
                    {translate('dangerZone')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="3"
                      inactiveText={translate('open')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="3">
              <DangerZone microservice={microservice} />
            </Accordion.Collapse>
          </>
        )
      }
    </Accordion>
  );
};

export default Configuration;
