import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useGetRedisMicroserviceQuery } from 'api/redisMicroserviceSlice';
import { Error, Spinner } from '@apex/react-toolkit/components';
import RedisConnectionDetails from 'common/applicationMicroservice/redis/RedisConnectionDetails';
import { IApplicationMicroserviceRedis } from 'types/application/microservice/IRedisMicroservice';
import MicroserviceChartVersionSelectorCard from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard';
import ApexHelmChartVersionExplainTooltip from 'common/applicationMicroservice/ApexHelmChartVersionExplainTooltip';
import AccordionToggleLink from 'common/AccordionToggleLink';
import DangerZone from './DangerZone';

const RedisMicroserviceConfiguration: React.FC<{
  microservice: IApplicationMicroserviceRedis;
  isDeveloperAuthorized?: boolean;
}> = ({ microservice, isDeveloperAuthorized = false }) => {
  const { microserviceable: redisMicroservice } = microservice;
  const { data: redisInstance, isFetching, error } = useGetRedisMicroserviceQuery(redisMicroservice.id);

  if (error) {
    return (<Error error={error} />);
  }

  if (isFetching) {
    return (<Spinner size="5x" />);
  }

  const redisInstanceExists = !!(redisInstance?.dev_instance || redisInstance?.staging_instance || redisInstance?.prod_instance);

  return (
    <Accordion alwaysOpen>
      {!redisInstanceExists && (
        <Card bg="dark" className="mt-3">
          <Card.Header>
            <Row>
              <Col className="text-start text-warning">
                {translate('noRedisInstancesExistYet')}
              </Col>
            </Row>
          </Card.Header>
        </Card>
      )}

      <Row className="mb-4">
        <Col>
          <Card bg="dark" className="mt-3">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('apexRedisVersions')}  <ApexHelmChartVersionExplainTooltip />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <span className="text-muted">
                        {translate('dev')} {microservice.dev_chart_version.version}
                      </span>
                    </Col>
                    <Col>
                      <span className="text-muted">
                        {translate('staging')} {microservice.staging_chart_version.version}
                      </span>
                    </Col>
                    <Col>
                      <span className="text-muted">
                        {translate('prod')} {microservice.prod_chart_version.version}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="apex-versions"
                    inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                    activeText={translate('close')}
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="apex-versions">
            <MicroserviceChartVersionSelectorCard
              microservice={microservice}
              isDeveloperAuthorized={isDeveloperAuthorized}
            />
          </Accordion.Collapse>
        </Col>
      </Row>

      {redisInstance?.dev_instance && (<RedisConnectionDetails
        microservice={microservice}
        environment="dev"
        instance={redisInstance.dev_instance}
        isDeveloperAuthorized={isDeveloperAuthorized}
      />)}
      {redisInstance?.staging_instance && (<RedisConnectionDetails
        microservice={microservice}
        environment="staging"
        instance={redisInstance.staging_instance}
        isDeveloperAuthorized={isDeveloperAuthorized}
      />)}
      {redisInstance?.prod_instance && (<RedisConnectionDetails
        microservice={microservice}
        environment="prod"
        instance={redisInstance.prod_instance}
        isDeveloperAuthorized={isDeveloperAuthorized}
      />)}
      {
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start text-danger">
                    {translate('dangerZone')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="2"
                      inactiveText="Open"
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>

            <Accordion.Collapse eventKey="2">
              <DangerZone microservice={microservice} />
            </Accordion.Collapse>
          </>
        )
      }
    </Accordion>
  );
};

export default RedisMicroserviceConfiguration;
