import { apiSlice } from 'api/apiSlice';
import IRedisMicroservice from 'types/application/microservice/IRedisMicroservice';
import Environment from 'types/Environment';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addRedisMicroservice: builder.mutation({
      query: data => ({ url: 'redis-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    getRedisMicroservice: builder.query<IRedisMicroservice, string>({
      query: id => ({ url: `redis-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),
    updateRedisPassowrd: builder.mutation<null, { redisMsId: string; environment: Environment }>({
      query: ({ redisMsId, environment }) =>
        ({ url: `redis-microservices/${redisMsId}/update-password`, method: 'put', data: { environment } }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    deleteRedisMicroservice: builder.mutation<null, IRedisMicroservice>({
      query: (data: IRedisMicroservice) => ({
        url: `redis-microservices/${data.id}`, method: 'delete',
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useGetRedisMicroserviceQuery,
  useAddRedisMicroserviceMutation,
  useUpdateRedisPassowrdMutation,
  useDeleteRedisMicroserviceMutation,
} = extendedApiSlice;
