import { apiSlice } from 'api/apiSlice';
import PaginatedResult from 'types/PaginatedResult';
import IQueryParameters from 'types/IQueryParameters';
import IPipeline from 'types/IPipeline';
import IFilesystemMicroservice from 'types/application/microservice/IFilesystemMicroservice';
import { IApplicationMicroserviceComputeBound, IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import Environment from 'types/Environment';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({

    addApplicationMicroservice: builder.mutation({
      query: data => ({ url: 'application-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

    getApplicationMicroservice: builder.query({
      query: id => ({ url: `application-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),

    listApplicationMicroservices: builder.query({
      query: queryParameters => {
        return { url: 'application-microservices', method: 'get', queryParameters };
      },
    }),

    getApplicationMicroserviceRoles: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/roles`, method: 'get', queryParameters };
      },
    }),

    editApplicationMicroservice: builder.mutation({
      query: data => ({ url: `application-microservices/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationMicroservices', id }],
    }),

    deleteApplicationMicroservice: builder.mutation({
      query: id => ({ url: `application-microservices/${id}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

    getApplicationMicroserviceJobs: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/jobs`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationMicroserviceJobs'],
    }),

    getApplicationMicroserviceDeployments: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/deployments`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationMicroserviceDeployments'],
    }),

    getApplicationMicroserviceImageTags: builder.query<[{ imageDigest: string; imageTag: string }], { queryParameters?: IQueryParameters, microserviceId: string }>({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/image-tags`, method: 'get', queryParameters };
      },
    }),

    promoteApplicationMicroserviceDeployment: builder.mutation({
      query: ({ deploymentId }) => ({ url: `deployments/promote/${deploymentId}`, method: 'post' }),
      invalidatesTags: () => [{ type: 'ApplicationMicroserviceDeployments' }],
    }),

    retryApplicationMicroserviceDeployment: builder.mutation({
      query: ({ deploymentId }) => ({ url: `deployments/retry/${deploymentId}`, method: 'post' }),
      invalidatesTags: () => [{ type: 'ApplicationMicroserviceDeployments' }],
    }),

    getApplicationMicroserviceEnvironmentVariables: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/environment-variables`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroserviceEnvironmentVariables'],
    }),

    editApplicationMicroserviceEnvironmentVariables: builder.mutation({
      query: ({ microserviceId, environmentVariables }) => ({
        url: `application-microservices/${microserviceId}/environment-variables`,
        method: 'post',
        data: { environment_variables: environmentVariables },
      }),
      invalidatesTags: ['ApplicationMicroserviceEnvironmentVariables'],
    }),

    getApplicationMicroserviceEventNotifications: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/event-notifications`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroserviceEventNotifications'],
    }),

    editApplicationMicroserviceEventNotifications: builder.mutation({
      query: ({ microserviceId, eventNotifications }) => ({
        url: `application-microservices/${microserviceId}/event-notifications`,
        method: 'post',
        data: { event_notifications: eventNotifications },
      }),
      invalidatesTags: ['ApplicationMicroserviceEventNotifications'],
    }),

    updateApplicationMicroserviceHttpPort: builder.mutation({
      query: data => ({ url: `application-microservices/${data.id}/http-port`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationMicroservices', id }],
    }),

    deployApplicationMicroservice: builder.mutation({
      query: ({ microserviceId, environment, imageTag, uninstall = false, }) => ({
        url: `application-microservices/${microserviceId}/deploy`,
        method: 'post',
        data: { environment, image_tag: imageTag, uninstall },
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroserviceDeployments' }],
    }),

    getApplicationMicroserviceKubernetesRoles: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/kubernetes-roles`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroserviceKubernetesRoles'],
    }),

    editApplicationMicroserviceKubernetesRoles: builder.mutation({
      query: ({ microserviceId, roles }) => ({
        url: `application-microservices/${microserviceId}/kubernetes-roles`,
        method: 'put',
        data: { roles: roles },
      }),
      invalidatesTags: ['ApplicationMicroserviceKubernetesRoles'],
    }),

    editMicroserviceS3Access: builder.mutation({
      query: ({ microserviceId, iamPolicyableMicroserviceIds }) => ({
        url: `application-microservices/${microserviceId}/grant-iam-access`,
        method: 'PUT',
        data: { iam_policyable_microservice_ids: iamPolicyableMicroserviceIds },
      }),
      invalidatesTags: () => [{ type: 'IamAccessibleMicroservices' }],
    }),

    getConsumesIamPolicyMicroservices: builder.query<IApplicationMicroserviceComputeBound[], { microserviceId: string }>({
      query: ({ microserviceId }) => ({
        url: `application-microservices/${microserviceId}/iam-accessible-microservices`,
        method: 'GET',
      }),
      providesTags: ['IamAccessibleMicroservices'],
    }),

    getApplicationMicroservicePods: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/pods`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroservicePods'],
    }),

    getMicroservicePipelines: builder.query<PaginatedResult<IPipeline>, { queryParameters?: IQueryParameters, microserviceId: string }>({
      query: ({ queryParameters = {}, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/pipelines`, method: 'get', queryParameters };
      },
    }),

    getFilesystems: builder.query<IFilesystemMicroservice[], string>({
      query: (microserviceId: string) => {
        return { url: `application-microservices/${microserviceId}/filesystems`, method: 'get' };
      },
      providesTags: ['ApplicationMicroservices'],
    }),

    updateMsHelmChartVersion: builder.mutation<IBaseApplicationMicroservice, { data: { version_id: string; environment: Environment; }; microserviceId: string; }>({
      query: ({ data, microserviceId }) => ({ url: `application-microservices/${microserviceId}/update-helm-char-version`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useAddApplicationMicroserviceMutation,
  useLazyListApplicationMicroservicesQuery,
  useGetApplicationMicroserviceQuery,
  useLazyGetApplicationMicroserviceRolesQuery,
  useEditApplicationMicroserviceMutation,
  useDeleteApplicationMicroserviceMutation,
  useLazyGetApplicationMicroserviceJobsQuery,
  useLazyGetApplicationMicroserviceDeploymentsQuery,
  usePromoteApplicationMicroserviceDeploymentMutation,
  useRetryApplicationMicroserviceDeploymentMutation,
  useLazyGetApplicationMicroserviceEnvironmentVariablesQuery,
  useEditApplicationMicroserviceEnvironmentVariablesMutation,
  useLazyGetApplicationMicroserviceEventNotificationsQuery,
  useEditApplicationMicroserviceEventNotificationsMutation,
  useUpdateApplicationMicroserviceHttpPortMutation,
  useGetApplicationMicroserviceImageTagsQuery,
  useDeployApplicationMicroserviceMutation,
  useGetApplicationMicroserviceKubernetesRolesQuery,
  useEditApplicationMicroserviceKubernetesRolesMutation,
  useGetApplicationMicroservicePodsQuery,
  useEditMicroserviceS3AccessMutation,
  useGetConsumesIamPolicyMicroservicesQuery,
  useGetMicroservicePipelinesQuery,
  useGetFilesystemsQuery,
  useUpdateMsHelmChartVersionMutation,
} = extendedApiSlice;
