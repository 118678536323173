import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { translate } from "@apex/react-toolkit/lib";
import FormFeedback from "common/FormFeedback";
import { IProbeConfigApiErrors } from 'types/application/microservice/IProbeConfig';

export interface InitialValues {
  healthcheck_path: string;
  initial_delay_seconds: number;
  timeout_seconds: number;
}

const defaultInitialValues: InitialValues = {
  healthcheck_path: 'healthcheck',
  initial_delay_seconds: 1,
  timeout_seconds: 5,
};

const ProbeConfigForm: React.FC<{
  initialValues?: InitialValues;
  routePrefix: string;
  onSubmit: (values: InitialValues) => void;
  disabled?: boolean;
  apiErrors?: IProbeConfigApiErrors;
}> = ({
  initialValues = defaultInitialValues,
  routePrefix,
  disabled = false,
  onSubmit,
  apiErrors,
}) => {
    const schema = yup.object({
      healthcheck_path: yup.string()
        .trim()
        .matches(/^(?!.*[/]{2})[a-z][a-z0-9-/]*[a-z0-9]$/, translate('invalidFormat')),
        initial_delay_seconds: yup.number().required().integer().min(1),
        timeout_seconds: yup.number().required().integer().min(1),
    });

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Label>{translate("healthcheckPath")}</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>{routePrefix.startsWith('/') ? routePrefix.substring(1) : routePrefix}/</InputGroup.Text>
                  <Form.Control
                    required
                    name="healthcheck_path"
                    type="text"
                    placeholder={translate("healthcheckPath")}
                    value={values.healthcheck_path}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                    isValid={touched.healthcheck_path && !errors.healthcheck_path}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="healthcheck_path"
                  />
                </InputGroup>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3" controlId="formInitialDelaySeconds">
                  <Form.Label>{translate('initialDelaySeconds')}</Form.Label>
                  <Form.Control
                    required
                    name="initial_delay_seconds"
                    type="number"
                    placeholder={translate('initialDelaySeconds')}
                    value={values.initial_delay_seconds}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                    isValid={touched.initial_delay_seconds && !errors.initial_delay_seconds}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="initial_delay_seconds"
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3" controlId="formTimeoutSeconds">
                  <Form.Label>{translate('timeoutSeconds')}</Form.Label>
                  <Form.Control
                    required
                    name="timeout_seconds"
                    type="number"
                    placeholder={translate('timeout_seconds')}
                    value={values.timeout_seconds}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                    isValid={touched.timeout_seconds && !errors.timeout_seconds}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="timeout_seconds"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button style={{ marginTop: '2rem' }} type="submit">{translate('save')}</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default ProbeConfigForm;
